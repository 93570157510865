import { useEffect, useRef, useState } from "preact/hooks";

interface YouTubeEmbedProps {
  videoId: string;
  startTime: number;
  onClose: () => void;
}

export function YouTubeEmbed({ videoId, startTime, onClose }: YouTubeEmbedProps) {
  const iframeRef = useRef<HTMLIFrameElement>(null);
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    if (iframeRef.current) {
      iframeRef.current.src = `https://www.youtube.com/embed/${videoId}?start=${startTime}&autoplay=1&loop=1&playlist=${videoId}`;
    }
  }, [videoId, startTime]);

  const handleClose = () => {
    setIsVisible(false);
    onClose();
  };

  if (!isVisible) return null;

  return (
    <div class="relative">
      <iframe
        ref={iframeRef}
        width="280"
        height="157"
        frameBorder="0"
        allow="autoplay; encrypted-media"
        allowFullScreen
      ></iframe>
      <button
        onClick={handleClose}
        class="absolute top-0 right-0 bg-red-500 text-white p-1 rounded-full"
      >
        X
      </button>
    </div>
  );
}